/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

global.$ = require('jquery');
// import $ from 'jquery/';
import 'bootstrap';
import './functionality/cookies-alert';
import './functionality/add-items-collection';

import 'jquery-typeahead';

// Import TinyMCE
import tinymce from 'tinymce/tinymce';

// A theme is also required
import 'tinymce/icons/default';
import 'tinymce/themes/silver';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';

import 'jquery-typeahead';

// any CSS you require will output into a single css file (app.css in this case)
import '../css/app.scss';

(function () {
    'use strict';
    $(function () {
        $('[data-toggle="tooltip"]').tooltip();

        $('.rich-editor').each(function (index, element) {
            tinymce.init({
                selector: '.rich-editor',
                height: 300,
                menubar: false,
                branding: false,
                plugins: ['paste', 'link', 'lists'],
                toolbar: 'undo redo | fontsizeselect | ' +
                    ' bold italic underline strikethrough backcolor | link | alignleft aligncenter ' +
                    ' alignright | bullist numlist outdent indent | ' +
                    ' removeformat'
            });
        });


        /* Confirm modal */
        $('.confirm-modal').on('show.bs.modal', function (e) {
            $(this).find('.btn-ok').attr('href', $(e.relatedTarget).attr('href'));
            $(this).find('.element').text($(e.relatedTarget).data('element'));
        });

        $( '[data-item-add]' ).click(function() {
            let idItem = $(this).data('item-add');
            $( '[data-item-add="'+idItem+'"]' ).addClass('d-none');
            $( '[data-item-edit-cont="'+idItem+'"]' ).removeClass('d-none');
            $( '[data-item-text="'+idItem+'"]' ).addClass('d-none');
            $( '[data-item-edit="'+idItem+'"]').removeClass('d-none');
        });

        $( '[data-item-edit]' ).click(function() {
            let idItem = $(this).data('item-edit');
            $( '[data-item-add="'+idItem+'"]' ).removeClass('d-none');
            $( '[data-item-edit-cont="'+idItem+'"]' ).removeClass('d-none');
            $( '[data-item-text="'+idItem+'"]' ).addClass('d-none');
            $( '[data-item-edit="'+idItem+'"]').addClass('d-none');
        });

        $( '[data-item-cancel]' ).click(function() {
            let idItem = $(this).data('item-cancel');
            $( '[data-item-add="'+idItem+'"]' ).removeClass('d-none');
            $( '[data-item-edit-cont="'+idItem+'"]' ).addClass('d-none');
            $( '[data-item-text="'+idItem+'"]' ).removeClass('d-none');
            $( '[data-item-edit="'+idItem+'"]').removeClass('d-none');
        });

        $( '[data-submit-onchange]' ).change(function() {
            $(this).closest('form').submit();
        });

        $( "[data-save-case]" ).click(function() {
            let $element = $(this);
            $element.attr('disabled','disabled');
            $.ajax({
                type: "GET",
                url: $element.data('url'),
                complete: function(data, textStatus){
                    switch (data.status) {
                        case 200:
                        case 201:
                            $element.find('.mdi').toggleClass('mdi-star-outline mdi-star text-gray-800 text-yellow');
                            $element.removeAttr('disabled');
                            break;
                        default:
                            console.log(data);
                            $element.removeAttr('disabled');
                            break;
                    }
                }
            });
        });

    });

    typeof $.typeahead === 'function' && $.typeahead({
        input: '[data-autocomplete="items"]',
        minLength: 1,
        maxItem: 0,
        // maxItemPerGroup: 6,
        order: "asc",
        hint: true,
        searchOnFocus: true,
        blurOnTab: false,
        multiselect: {
            matchOn: ["id"],
            cancelOnBackspace: true,
        },
        templateValue: "{{display}}",
        display: ["display"],
        emptyTemplate: 'no result for {{query}}',

        selector: {
            container: "typeahead__container",
            result: "typeahead__result",
            list: "typeahead__list",
            group: "typeahead__group",
            item: "typeahead__item",
            empty: "typeahead__empty",
            display: "typeahead__display",
            query: "typeahead__query",
            filter: "typeahead__filter",
            filterButton: "typeahead__filter-button",
            dropdown: "typeahead__dropdown",
            dropdownItem: "typeahead__dropdown-item",
            labelContainer: "typeahead__label-container",
            label: "typeahead__label",
            button: "typeahead__button",
            backdrop: "typeahead__backdrop",
            hint: "typeahead__hint",
            cancelButton: "typeahead__cancel-button"
        },
        debug: true
    });

    $.typeahead({
        input: '[data-autocomplete="disease"]',
        minLength: 1,
        order: "asc",
        offset: true,
        hint: true,
        source: {
             items: {
                 ajax: {
                     type: "POST",
                     url:  window.location.origin + "/app/suggest-disease",
                     data: {
                         myKey: $('[data-autocomplete="disease"]').val()
                     }
                 }
             }
         },
    });


})();