(function () {
    'use strict';

    let addTagForm = function ($collectionHolder, $newLinkLi) {
        // Get the data-prototype explained earlier
        var prototype = $collectionHolder.data('prototype');

        // get the new index
        var index = $collectionHolder.data('index');

        var newForm = prototype;
        // You need this only if you didn't set 'label' => false in your tags field in TaskType
        // Replace '__name__label__' in the prototype's HTML to
        // instead be a number based on how many items we have
        // newForm = newForm.replace(/__name__label__/g, index);

        // Replace '__name__' in the prototype's HTML to
        // instead be a number based on how many items we have
        newForm = newForm.replace(/__name__/g, index);

        // increase the index with one for the next item
        $collectionHolder.data('index', index + 1);


        // Display the form in the page in an li, before the "Add a tag" link li
        var $newFormLi = $('<li></li>').append(newForm);
        $newFormLi.append('<button type="button" class="btn btn-link remove-tag ml-3" title="'+ deleteItemText +'"><span class="mdi mdi-close-circle text-size-6"></span></button>');
        $newLinkLi.before($newFormLi);

        // handle the removal, just for this example
        $('.remove-tag').click(function(e) {
            e.preventDefault();

            $(this).parent().remove();

            return false;
        });
    };

    var $collectionHolder;

    // setup an "add a tag" link
    var $addTagButton = $('<button type="button" class="btn btn-primary add-tag-ink">'+ addItemText +' <span class="mdi mdi-plus-circle"></span></button>\'');
    var $newLinkLi = $('<li></li>').append($addTagButton);

    $(function () {
        // Get the ul that holds the collection of tags
        $collectionHolder = $('ul.collection-lst');

        // add the "add a tag" anchor and li to the tags ul
        $collectionHolder.append($newLinkLi);

        // count the current form inputs we have (e.g. 2), use that as the new
        // index when inserting a new item (e.g. 2)
        $collectionHolder.data('index', $collectionHolder.find(':input').length);

        $addTagButton.on('click', function(e) {
            // add a new tag form (see next code block)
            addTagForm($collectionHolder, $newLinkLi);
        });
    });

})();
